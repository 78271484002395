<template>
  <section>
      <el-form ref="form" label-position="right" status-icon :model="form" :rules="rules" label-width="180px" style="width:95%;overflow:auto">
        <el-form-item label="结果照片" prop="resultUrl">
          <el-image
            
            :src="sampleDetail.resultUrl"
            title="可点击放大查看"
            :preview-src-list="[sampleDetail.resultUrl]">
          </el-image>
        </el-form-item>
        <el-form-item label="结果" prop="result">
          <el-radio-group  v-model="form.result">
            <el-radio :label="0">通过</el-radio>
            <el-radio :label="1">不通过</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="备注" prop="rmk">
          <el-input type="textarea" placeholder="请填写备注" v-model="form.rmk"></el-input>
        </el-form-item>
        <el-form-item>
            <el-button @click="close('form')">取消</el-button>
            <el-button type="primary" @click="submit('form')" :plain='true'>提交</el-button>
        </el-form-item>
      </el-form>
    </section>
</template>

<script>
import { judgmentResult,getSampleDetail } from '@/api/public.js';
export default {
  props:['row'],
  data() {
    return {
      form:{
        pipelineId:"",
        result:"",
        rmk:"",
      },
      sampleDetail:{}, // 样品详情
      rules:{
        result:[
          { required: true, message: '判定结果不得为空', trigger: 'blur', }
        ]
      }
    }
  },

  components: {},

  computed: {},

  mounted() {
    this.form.pipelineId = this.row ? this.row.pipelineId : this.form.pipelineId;
    this.form.result = this.row ? this.row.result : this.form.result;
    this.$nextTick(()=>{
      this.getSampleInfo();
    })
  },

  methods: {
    async getSampleInfo(){
      await getSampleDetail({param:this.row.sampleId}).then(res=>{
        if(res.success){
          this.sampleDetail = res.data;
        }else{
          this.$message({
            message:'获取结果图片失败，请重试',
            type:'error'
          })
          this.$emit('handleClose');
        }
      })
    },
    submit(){
      this.$refs["form"].validate(valid => {
        if(valid){
          this.$confirm('确认提交该结果吗？').then( async () => {
            await judgmentResult({param:this.form}).then(res=>{
              if(res.success){
                this.$message({
                  message:'提交成功',
                  type:'success'
                })
                this.$emit('handleClose')
              }else{
                this.$message({
                  message:res.msg,
                  type:'fail'
                })
              }
            })
          })
        }
      })
    }
  },
};
</script>
<style scoped></style>
