<!-- 检测记录 -->
<template>
  <div class="outContainer">
    <!-- 筛选条件栏 -->
    <div class="search">
      <el-input
        class="each"
        v-model="searchInfo.param.name"
        placeholder="用户姓名"
      ></el-input>
      <el-input
        class="each"
        v-model="searchInfo.param.phone"
        placeholder="联系电话"
      ></el-input>
      <el-input
        class="each"
        v-model="searchInfo.param.cabinetMachineId"
        placeholder="柜机编号"
      ></el-input>
      <!-- 检测进度 、是否合格 -->
      <el-cascader
        class="each"
        v-model="status"
        placeholder="检测进度 / 是否合格"
        clearable
        :options="statusOptions"
        :props="{ expandTrigger: 'hover', checkStrictly: true }"
        @change="chooseStatus"
      ></el-cascader>
      <!-- 系统、流水模板 -->
      <el-cascader
        class="each"
        clearable
        placeholder=" 所属系统 / 流水模板 "
        v-model="system"
        :options="systemTypeEnumOptions"
        :props="{
          expandTrigger: 'hover',
          value: 'id',
          label: 'name',
          checkStrictly: true,
        }"
        @change="handleChoose"
      ></el-cascader>
      <!-- 时间范围 -->
      <el-date-picker
        v-model="time"
        type="daterange"
        range-separator="至"
        start-placeholder="检测时间"
        end-placeholder="检测时间"
        @change="chooseTime"
        class="timeSelect each"
      >
      </el-date-picker>
      <el-button
        size="mini"
        type="primary"
        icon="el-icon-search"
        @click="hanldleSearch('search')"
        >搜索</el-button
      >
      <el-button
        size="mini"
        type="primary"
        icon="el-icon-close"
        @click="hanldleSearch('clear')"
        >清空搜索条件</el-button
      >
    </div>
    <!-- 表格内容 -->
    <div class="tableContainer">
      <el-table :data="tableData" height="100%" style="width: 100%">
        <el-table-column
          align="center"
          label="柜机编号"
          prop="cabinetMachineId"
        >
        </el-table-column>
        <el-table-column align="center" label="系统名称" prop="typeName">
        </el-table-column>
        <el-table-column align="center" label="流水线id" prop="pipelineId">
          <template v-slot="{ row }">
            {{ row.pipelineId ? row.pipelineId : '空闲' }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="检测用户" prop="user">
          <template slot-scope="scope">
            <p>{{ scope.row.name ? scope.row.name : '--' }}</p>
            <p>{{ scope.row.phone ? scope.row.phone : '--' }}</p>
          </template>
        </el-table-column>
        <el-table-column align="center" label="样品详情" prop="name">
          <template slot-scope="scope">
            <el-button size="mini" @click="openSample(scope.row)"
              >查看</el-button
            >
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="样品位置"
          prop="samplePositionName"
        >
        </el-table-column>
        <el-table-column align="center" label="检测进度" prop="statusName">
        </el-table-column>
        <el-table-column align="center" label="检测结果" prop="resultName">
          <template slot-scope="scope">
            {{
              scope.row.resultName && scope.row.status == 2
                ? scope.row.resultName
                : '--'
            }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="创建时间" prop="createTime">
        </el-table-column>
        <el-table-column align="center" label="更新时间" prop="updateTime">
        </el-table-column>
        <el-table-column align="center" label="操作" fixed="right" width="220">
          <template slot-scope="scope">
            <el-button
              size="mini"
              @click="judgeResult(scope.row)"
              v-if="scope.row.status == 4"
              >结果判定</el-button
            >
            <el-button size="mini" @click="checkNode(scope.row)"
              >流水动作节点</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <pagination
      v-bind:child-msg="searchInfo"
      @callFather="callFather"
    ></pagination>
    <!-- 样品详情卡片 -->
    <div class="cover" v-if="showSample" @click="closeCover">
      <el-card
        :body-style="{ padding: '0px' }"
        class="inside"
        @click.native.stop
      >
        <img :src="sampleDetail.sampleUrl" class="image" />
        <div class="sampleInfo">
          <p>样品名称：{{ sampleDetail.name ? sampleDetail.name : '--' }}</p>
          <p>
            样品类型：{{ sampleDetail.typeName ? sampleDetail.typeName : '--' }}
          </p>
          <p>
            样品重量：{{
              sampleDetail.weight ? sampleDetail.weight + '克' : '--'
            }}
          </p>
          <p>
            样品来源：{{ sampleDetail.source ? sampleDetail.source : '--' }}
          </p>
          <p>
            来源时间：{{
              sampleDetail.sourceDatetimeStr
                ? sampleDetail.sourceDatetimeStr
                : '--'
            }}
          </p>
        </div>
      </el-card>
    </div>
    <!-- 流水节点 -->
    <el-dialog
      title="流水节点"
      :visible.sync="showNode"
      append-to-body
      width="80%"
    >
      <el-timeline style="height: 400px; overflow-y: auto">
        <!-- 父节点 -->
        <el-timeline-item
          v-for="(item, index) in activities"
          :key="index"
          :timestamp="item.name"
          type="primary"
          placement="top"
        >
          <!-- 子节点 -->
          <el-steps finish-status="success">
            <el-step
              :status="
                ite.status == 3
                  ? 'error'
                  : ite.status == 2
                  ? 'success'
                  : 'process'
              "
              v-for="ite in item.actions"
              :key="ite.id"
            >
              <template slot="description">
                <p>{{ ite.name }}</p>
                <p>{{ ite.createTime }}</p>
                <el-image
                  v-if="ite.image"
                  style="width: 50px; height: 50px"
                  :src="ite.image"
                  title="点击放大图片"
                  :preview-src-list="[ite.image]"
                >
                </el-image>
              </template>
            </el-step>
          </el-steps>
        </el-timeline-item>
      </el-timeline>
    </el-dialog>

    <el-dialog title="流水节点" :visible.sync="dialogVisible" width="80%">
      <div class="container">
        <steps :data="startNodes"></steps>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">关闭</el-button>
      </span>
    </el-dialog>

    <!-- 结果判定 -->
    <el-drawer
      title="结果判定"
      :visible.sync="judgeDrawer"
      :before-close="handleClose"
      size="50%"
    >
      <judgement
        v-if="judgeDrawer"
        @handleClose="handleClose"
        ref="newForm"
        :row="row"
      ></judgement>
    </el-drawer>
  </div>
</template>

<script>
import pagination from '@/components/Pagination.vue'
import {
  getPipelineList,
  getBaseUrl,
  getSampleDetail,
  getPipelineTemplate,
  getsystemTypeEnum,
} from '@/api/public.js'
import { timeChange, timeToTime } from '@/api/publicFun.js'
import judgement from './judgement.vue'
import Steps from '../templateManage/assemblyLineTemplate/steps.vue'
export default {
  data() {
    return {
      searchInfo: {
        // 筛选条件
        pageNo: 1,
        pageSize: 10,
        total: 1,
        param: {
          name: null, // 用户名称
          phone: null, // 用户电话
          cabinetMachineId: null, // 柜机编号
          status: null, // 检测进度
          type: null, // 系统类型
          startDatetime: null, // 开始时间
          endDatetime: null, // 结束时间
          pipelineTemplateId: null, // 流水模板
        },
      },
      statusOptions: [
        // 检测进度选项
        {
          value: 1,
          label: '执行中',
        },
        {
          value: 2,
          label: '检测完成',
          children: [
            { value: 0, label: '通过' },
            { value: 1, label: '不通过' },
          ],
        },
        {
          value: 3,
          label: '检测失败',
        },
        {
          value: 4,
          label: '待审核',
        },
      ],
      status: [], // 检测进度/是否合格 绑定数据
      tableData: [], // 表格数据
      showSample: false, // 是否显示样品详情卡片
      judgeDrawer: false, // 结果判定弹框是否显示
      showNode: false, // 流水节点弹框是否显示
      activities: [], // 流水节点列表
      system: [],
      time: '', // 检测时间范围筛选绑定值
      sampleDetail: {}, // 样品详情
      baseUrl: getBaseUrl(),
      systemTypeEnumOptions: [], // 系统类型、流水线枚举
      startNodes: [],
      dialogVisible: false,
    }
  },

  components: {
    pagination,
    judgement,
    Steps,
  },

  computed: {},

  mounted() {
    this.init()
    this.getSystemList()
  },

  methods: {
    // 选择时间范围
    chooseTime(e) {
      this.searchInfo.param.startDatetime = timeChange(e[0], 'day')
      this.searchInfo.param.endDatetime = timeChange(e[1], 'day')
    },
    // 选择检测进度
    chooseStatus(e) {
      this.searchInfo.param.status = e[0] ? e[0] : null
      this.searchInfo.param.result = e[1] || e[1] == 0 ? e[1] : null
    },
    // 选择系统类型、流水模板
    handleChoose(e) {
      this.searchInfo.param.type = e[0] ? e[0] : null
      this.searchInfo.param.pipelineTemplateId = e[1] ? e[1] : null
    },
    /**
     * 筛选事件
     * @param {String} type 'clear'清空筛选条件 'search'搜索
     */
    hanldleSearch(type) {
      this.searchInfo.pageNo = 1
      if (type == 'clear') {
        this.searchInfo.param = {
          name: null,
          phone: null,
          cabinetMachineId: null,
          status: null,
          type: null,
          startDatetime: null,
          endDatetime: null,
          pipelineTemplateId: null,
        }
        this.time = ''
        this.system = []
        this.status = []
      } else {
        this.searchInfo.param.name =
          this.searchInfo.param.name == '' ? null : this.searchInfo.param.name
        this.searchInfo.param.phone =
          this.searchInfo.param.phone == '' ? null : this.searchInfo.param.phone
      }
      this.init()
    },
    // 获取系统/流水模板枚举
    async getSystemList() {
      this.systemTypeEnumOptions = []
      await getsystemTypeEnum().then(res => {
        if (res.success) {
          res.data.forEach(ele => {
            let each = { id: ele.code, name: ele.desc, children: [] }
            this.systemTypeEnumOptions.push(each)
          })
          this.systemTypeEnumOptions.forEach(async element => {
            await getPipelineTemplate({ param: element.id }).then(
              pipelineRes => {
                if (pipelineRes.success) {
                  element.children = pipelineRes.data
                } else {
                  element.children = []
                }
              }
            )
          })
        }
      })
    },
    // 初始化获取表格数据
    async init() {
      await getPipelineList(this.searchInfo).then(res => {
        if (res.success) {
          this.tableData = res.data.list
          this.searchInfo.total = Number(res.data.total)
          this.tableData.forEach(element => {
            element.createTime = timeChange(element.createTime, 'seconds')
            element.updateTime = timeChange(element.updateTime, 'seconds')
          })
        } else {
          this.tableData = []
          this.searchInfo.total = 0
        }
      })
    },
    // 打开样品详情卡片
    async openSample(row) {
      await getSampleDetail({ param: row.sampleId }).then(res => {
        if (res.success) {
          this.sampleDetail = res.data
          this.sampleDetail.sourceDatetimeStr = this.sampleDetail.sourceDatetime
            ? timeToTime(this.sampleDetail.sourceDatetime, 'day')
            : null
          this.showSample = true
        } else {
          this.$message({
            message: '获取信息失败，请重试',
            type: 'error',
          })
        }
      })
    },
    // 关闭样品详情卡片
    closeCover() {
      this.showSample = false
    },
    // 打开查看流水节点弹框
    checkNodeBak(row) {
      console.log(row.actions, 'row.actions')
      if (row.nodes) {
        this.activities = row.nodes
        this.activities.forEach(element => {
          element.actions.forEach(ele => {
            ele.createTimeStr = timeToTime(ele.createTime, 'seconds')
          })
        })
        this.showNode = true
      } else {
        this.$message({
          message: '暂无流水节点',
          type: 'fail',
        })
      }
    },
    // 打开查看流水节点弹框
    checkNode(row) {
      console.log(row.actions, 'row.actions')
      if (row.actions) {
        this.dialogVisible = true
        // this.startNodes = handleNodes(row.actions)
        row.actions = row.actions.map(item => {
          item.origin = item
          return item
        })
        this.startNodes.splice(
          0,
          this.startNodes.length,
          // ...handleNodes(row.actions)
          ...this.handleGroups(row.actions)
        )
        this.startNodes = [this.startNodes]

        function handleNodes(nodes) {
          return nodes.map(node => {
            return {
              name: node.name,
              origin: node,
              id: node.id,
            }
          })
        }
      } else {
        this.$message({
          message: '暂无流水节点',
          type: 'fail',
        })
      }
    },
    handleGroups(arr) {
      // 通过groupid提炼对应map，区分成散节点和块节点。 区分大节点数组和小节点块级数组样式渲染,尝试使用id排序
      // 添加节点和块下标进行顺序校正

      function handleArr2MapByGroupId(arr) {
        return arr.reduce((result, arrItem, index) => {
          arrItem.index = index
          let groupId = arrItem.origin.groupId ?? 'null'
          if (result.has(groupId)) {
            result.get(groupId).push(arrItem)
          } else {
            result.set(groupId, [arrItem])
          }
          return result
        }, new Map())
      }
      function handleMapToArr(map) {
        let arr = []
        for (const [key, value] of map) {
          if (key === 'null') {
            arr.push(...value)
          } else {
            arr.push(value)
          }
        }
        arr = arr.map(item => {
          if (Array.isArray(item)) {
            item.index = item[0].index
            item.block = true
          }
          return item
        })
        return arr
      }

      function handleArrSort(arr) {
        console.log('!!!')
        return arr.sort((a, b) => a.index - b.index)
      }
      let resultArr = handleMapToArr(handleArr2MapByGroupId(arr))
      // console.log(resultArr, 'map2arr')
      resultArr = handleArrSort(resultArr)
      // console.log(resultArr, 'sort')
      return resultArr
    },
    // 打开结果判定弹框
    judgeResult(row) {
      this.row = row
      this.judgeDrawer = true
    },
    // 关闭
    handleClose() {
      this.judgeDrawer = false
      this.row = null
      this.init()
    },
    // 分页插件事件
    callFather(parm) {
      this.searchInfo.pageNo = parm.currentPage
      this.init()
    },
  },
}
</script>
<style scoped lang="scss">
// 步骤条不同状态 颜色自定义
/deep/ .el-step__description.is-process {
  color: #1890ff !important;
}
/deep/ .el-step__head.is-process {
  color: #1890ff !important;
  border-color: #1890ff !important;
}
/deep/ .el-step__description.is-success {
  color: green !important;
}
/deep/ .el-step__head.is-success {
  color: green !important;
  border-color: green !important;
}
.sampleInfo {
  padding: 14px;
  p {
    text-align: left;
    line-height: 20px;
    font-size: 16px;
  }
}
.time {
  font-size: 13px;
  color: #999;
}

.bottom {
  margin-top: 13px;
  line-height: 12px;
}

.button {
  padding: 0;
  float: right;
}

.image {
  width: 100%;
  display: block;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}

.clearfix:after {
  clear: both;
}
/* 流水节点 */
.el-timeline-item__content {
  span {
    margin-bottom: 5px;
    display: inline-block;
    font-weight: 600;
    font-size: 18px;
  }
}
/deep/.el-timeline-item__timestamp {
  color: #000 !important;
  font-size: 18px !important;
  font-weight: 600 !important;
}
/deep/.el-step__description.is-wait,
/deep/.el-step__description.is-finish,
/deep/.el-step__description.is-error,
/deep/.el-step__description.is-success,
/deep/.el-step__description.is-process {
  margin-top: 5px;
}
</style>
